import { AutoComplete } from "primereact/autocomplete";

import React, { useEffect, useState } from "react";

import { DEVICE_MAPPINGS } from "./device/DeviceDetail";

import "./SearchBar.css";
import { formatValue } from "../lib/text";
import { useLocation } from "react-router-dom";
import InfoButton from "./screen/Info";



const formatTag = (key,value) => {
  return `${key}: ${formatValue(value)}`
}

/*
 * SearchBar Component with input and autocompletion
 * Handles keyboard events
 * key arrows up and down to navigate through suggestions
 */
const SearchBar = ({
  suggestionList,
  onFilter,
  hashTagOnly = false,
  mapKeyValues = DEVICE_MAPPINGS,
  placeholder = "Suche...",
  preFiltered = [],
}) => {
  const [value, setValue] = useState(preFiltered);
  const [items, setItems] = useState([])
  const location = useLocation();


  const search = ({query}) => {
    const found = []
    if(hashTagOnly){
      suggestionList.forEach((item) => {
        const _item = item.replace("#", "")
        const _query = query.replace("#", "")
        if(_item.toLowerCase().includes(_query.toLowerCase())) {
          found.push(item)
        }
    })
    }else{
    suggestionList.forEach((item) => {
        Object.keys(mapKeyValues).forEach((key) => {
          if(typeof item[key] === "string" && item[key].toLowerCase().includes(query.toLowerCase())) {
            found.push(formatTag(mapKeyValues[key], item[key]))
          }
          if(Array.isArray(item[key])) {
            item[key].forEach((_value) => {
              if(typeof _value === "string" && _value.toLowerCase().includes(query.toLowerCase())) {
                found.push(formatTag(mapKeyValues[key], _value))
              }
            })
          }
          if(mapKeyValues[key].toLowerCase().includes(query.toLowerCase())) {
            found.push(formatTag(mapKeyValues[key], item[key]))
          }
        })
    })
  }
    setItems([...new Set(found.map((s) => s.trim()))])
  };

  useEffect(() => {
    onFilter(value)
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.hash.length > 0) {
      const filters = decodeURIComponent(location.hash.substring(1)).split(",");
      filters.forEach((filter) => {
        const [key, value] = filter.split(":");
        if (key && value) {
          const filterObj = `${mapKeyValues[key]}: ${value}`
          addFilter(filterObj);
        } else if (key && hashTagOnly) {
          const filterObj = `#${key.replace("#", "")}`
          addFilter(filterObj);
        }
      });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps



  const addFilter = (filter) => {
    const newFilterList = [...value, filter];
    console.log("addFilter", newFilterList)
    setValue([...new Set(newFilterList.map((s) => s.trim()))])
  };


  return (
    <div className="card p-fluid search-bar-wrapper">
      <AutoComplete
        value={value}
        suggestions={items}
        completeMethod={search}
        onChange={(e) => setValue(e.value)}
        multiple
        placeholder={placeholder}
      />
      <InfoButton>
        Suche nach folgenden Kriterien möglich:
        <ul>
          {Object.values(mapKeyValues).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </InfoButton>
    </div>
  );

};

export default SearchBar;

import React, { useState, useEffect, useRef } from "react";
import INFO from "@style-guide/assets/pictograms/info.svg";
import { BdrPictogram } from "@style-guide/web-components-react";
import "./Info.css";
import { BodyText } from "./Text";

const InfoButton = ({ children }) => {
  const [infoPict, setInfoPict] = useState();
  const [showText, setShowText] = useState(false);
  const infoTextRef = useRef(null);

  useEffect(() => {
    fetch(INFO)
      .then((r) => r.text())
      .then((text) => {
        setInfoPict(text);
      });
  }, []);

  useEffect(() => {
    if (showText && infoTextRef.current) {
      const rect = infoTextRef.current.getBoundingClientRect();
      if (rect.left > window.innerWidth / 2) {
        infoTextRef.current.classList.add('right-aligned');
      } else {
        infoTextRef.current.classList.remove('right-aligned');
      }
    }
  }, [showText]);

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
      className="info-button"
    >
      <BdrPictogram pictogram={infoPict} className="info-icon" />
      {showText && (
        <div className="info-text" ref={infoTextRef}>
          <BodyText>{children}</BodyText>
        </div>
      )}
    </div>
  );
};

export default InfoButton;
